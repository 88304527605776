<template>
  <ion-page>
    <HeaderPage title="Kuis" />
    <ion-content :fullscreen="true">
      <section class="container" v-if="quiz">
        <div class="info-user">
          <div class="gretting">Ringkasan, <span>{{ $auth.name }}</span></div>
        </div>

        <div class="box-container">
          <div class="t">
            <span>Ketepatan</span>
            <span>{{ percent * 100 }}% </span>
          </div>
          <div class="progress">
            <ion-progress-bar class="progress" color="success" :value="percent"></ion-progress-bar>
          </div>
        </div>

        <div class="box-container space">
          <div class="s">Skor</div>
          <div class="point">
            {{ $filters.numFormat(quiz.point) }}
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0)">
              <path d="M11.9794 23.9587C18.5954 23.9587 23.9588 18.5954 23.9588 11.9794C23.9588 5.36334 18.5954 -1.52588e-05 11.9794 -1.52588e-05C5.36335 -1.52588e-05 0 5.36334 0 11.9794C0 18.5954 5.36335 23.9587 11.9794 23.9587Z" fill="#FFBC51"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9797 2.43952C12.3952 2.43952 12.8045 2.466 13.206 2.5176C13.5726 2.56462 13.9084 2.30529 13.9555 1.93872C14.0027 1.57214 13.7433 1.23629 13.3767 1.18922C12.9193 1.13041 12.453 1.1001 11.9797 1.1001C11.6101 1.1001 11.3101 1.40015 11.3101 1.76974C11.3101 2.13956 11.6101 2.43952 11.9797 2.43952Z" fill="#FCD28A"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M14.8733 2.88701C17.2311 3.6376 19.1921 5.28124 20.3591 7.41888C20.5361 7.7435 20.9433 7.86312 21.2677 7.68597C21.5921 7.50882 21.7117 7.10156 21.5346 6.7774C20.2041 4.3398 17.9678 2.46697 15.2797 1.61082C14.9276 1.49864 14.5506 1.6935 14.4384 2.04556C14.3263 2.39803 14.5212 2.7747 14.8733 2.88701Z" fill="#FCD28A"/>
              <path d="M11.9788 20.5844C16.7314 20.5844 20.5841 16.7317 20.5841 11.9792C20.5841 7.22661 16.7314 3.3739 11.9788 3.3739C7.22624 3.3739 3.37354 7.22661 3.37354 11.9792C3.37354 16.7317 7.22624 20.5844 11.9788 20.5844Z" fill="#FFB32C"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M8.04716 19.6344C5.27264 18.2057 3.37354 15.3123 3.37354 11.9791C3.37354 7.22997 7.22945 3.3739 11.9788 3.3739C14.0876 3.3739 16.0201 4.13408 17.5176 5.39569C16.3391 4.78819 15.0022 4.44533 13.586 4.44533C8.83659 4.44533 4.98068 8.3014 4.98068 13.0505C4.98068 15.6916 6.1727 18.0557 8.04716 19.6344Z" fill="#F49E17"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M11.3146 7.14659C11.4346 6.88772 11.6939 6.72217 11.9791 6.72217C12.2642 6.72217 12.5235 6.88772 12.6436 7.14659L13.8886 9.82854L16.8243 10.184C17.1074 10.2181 17.3449 10.4134 17.433 10.685C17.5211 10.9562 17.4439 11.2539 17.235 11.4478L15.0688 13.461L15.6381 16.3626C15.693 16.6425 15.5805 16.9286 15.3498 17.0964C15.1191 17.2641 14.8121 17.2826 14.5629 17.1436L11.9791 15.7058L9.39527 17.1436C9.1461 17.2826 8.83909 17.2641 8.60836 17.0964C8.37767 16.9286 8.26519 16.6425 8.32009 16.3626L8.88934 13.461L6.72319 11.4478C6.51428 11.2539 6.43702 10.9562 6.52514 10.685C6.61329 10.4134 6.85077 10.2181 7.13389 10.184L10.0695 9.82854L11.3146 7.14659Z" fill="white"/>
              <path fill-rule="evenodd" clip-rule="evenodd" d="M8.1618 10.0594C9.27244 13.1575 12.1111 15.4359 15.5141 15.7305L15.6381 16.3627C15.693 16.6423 15.5805 16.9284 15.3498 17.0961C15.1191 17.2637 14.812 17.2825 14.5629 17.1437L11.9791 15.7053L9.39528 17.1437C9.14606 17.2825 8.8391 17.2637 8.60837 17.0961C8.37764 16.9284 8.26519 16.6423 8.3201 16.3627L8.88935 13.4607L6.72319 11.448C6.51431 11.2536 6.43701 10.9562 6.52513 10.6846C6.61326 10.4136 6.85079 10.218 7.13386 10.1837L8.1618 10.0594Z" fill="#E9F2FE"/>
              </g>
              <defs>
              <clipPath id="clip0">
              <rect width="24" height="24" fill="white"/>
              </clipPath>
              </defs>
            </svg>
          </div>
        </div>

        <ion-button mode="ios" shape="round" expand="block" color="dark" class="btn mt-1 mb-1" @click="$router.push('/')">Main Lagi</ion-button>

        <router-link to="/event" class="link">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 12V22H4V12" stroke="#219653" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M22 7H2V12H22V7Z" stroke="#219653" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12 22V7" stroke="#219653" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12 7H7.5C6.83696 7 6.20107 6.73661 5.73223 6.26777C5.26339 5.79893 5 5.16304 5 4.5C5 3.83696 5.26339 3.20107 5.73223 2.73223C6.20107 2.26339 6.83696 2 7.5 2C11 2 12 7 12 7Z" stroke="#219653" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12 7H16.5C17.163 7 17.7989 6.73661 18.2678 6.26777C18.7366 5.79893 19 5.16304 19 4.5C19 3.83696 18.7366 3.20107 18.2678 2.73223C17.7989 2.26339 17.163 2 16.5 2C13 2 12 7 12 7Z" stroke="#219653" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>

          Tukar Kupon Undian

          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M6 12L10 8L6 4" stroke="#545454" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>

        </router-link>
      </section>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonContent, IonPage, IonButton, IonProgressBar
} from '@ionic/vue';
import HeaderPage from '@/components/headerPage'
import { defineComponent } from 'vue'
import { mapGetters } from 'vuex'

export default defineComponent({
  components: {
    IonContent,
    IonPage,
    HeaderPage,
    IonButton,
    IonProgressBar
  },
  data () {
    return {
      quiz: null,
      percent: 0
    }
  },
  watch: {
    '$route': 'fetchResult'
  },
  created () {
    this.fetchResult()
    this.$store.dispatch('auth/fetchUser')
  },
  methods: {
    fetchResult() {
      let id = this.$route.params.id
      if(id == undefined)
        return;

      this.$api.get(`/api/quiz/${id}`).then(res => {
        this.quiz = res.data
        let s = res.data.question_count
        let x = res.data.point
        let z = (s-x);
        this.percent = Math.floor((s-z) * 100 / s) / 100;
      })
    }
  },
  computed: {
    ...mapGetters({
      $auth: 'auth/getLoggedIn'
    })
  }
})
</script>

<style lang="scss" scoped>
.info-user {
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
  .gretting {
    margin-bottom: 12px;
    span {
      font-weight: bold;
    }
  }
}

.link {
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000000;
  svg {
    margin-right: 10px;
  }
}


.point {
  display: flex;
  align-items: center;
  font-size: 24px;
  justify-content: center;
  margin-bottom: 1rem;
  svg {
    margin-right: 1rem;
  }
}

.box {
  display: flex;
  height: 60px;
  box-shadow: 0px 1px 3px 1px rgb(0 0 0 / 16%);
  border-radius: 5px;
  padding: 10px 11px;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1rem;

  &.space {
    flex-direction: row !important;

    .point {
      margin: 0;
      svg {
        margin-left: 5px;
      }
    }
  }
  .progress {
    height: 10px;
    border-radius: 5px;
  }
}
.t {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
}
.s {
  line-height: 40px;
}
</style>
